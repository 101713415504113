.tableHead {
    background-color: black;
    z-index: 1300;
  }
  
  .tableHeadCell {
    color: #02134d;
    background-color: #e9eeff;
    font-size: 12px;
    padding: 2px;
    padding-left: 14px;
    font-weight: bold;
    z-index: 5; 

  }
  .tablebody{
    font-size: 13px;
    color: grey;
    padding: 12px;
    padding-left: 14px;
    

  }